import _toConsumableArray from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.test.js";
import { defineComponent } from 'vue';
import { german } from '@rose/common-ui';
import { settingsPaProphyZuordnungColumnsDefs } from '@/components/columns/settings/paProphyZuordnung';
import { Zuordnungsart } from '@/types/enums';
import { settingsHkpZuordnungColumnsDefs } from '@/components/columns/settings/hkpZuordnung';
import { PAType, HKPTerminType } from '@rose/types';
import { chain } from 'lodash';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  props: {
    zuordnungsart: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: {},
      rowClassRules: {},
      pendingZuordnungen: [],
      filter: ''
    };
  },
  computed: {
    terminartenNotConfigured: function terminartenNotConfigured() {
      if (this.zuordnungsart === Zuordnungsart.PA) {
        return editSettingsStore.getters.terminartenNotConfiguredPA;
      }

      if (this.zuordnungsart === Zuordnungsart.HKP) {
        return editSettingsStore.getters.terminartenNotConfiguredHKP;
      }

      return [];
    },
    terminartenConfigured: function terminartenConfigured() {
      if (this.zuordnungsart === Zuordnungsart.PA && !this.filter) {
        return editSettingsStore.getters.terminartenConfiguredPaKeys;
      }

      if (this.zuordnungsart === Zuordnungsart.HKP && !this.filter) {
        return editSettingsStore.getters.terminartenConfiguredHkpKeys;
      }

      if (this.zuordnungsart === Zuordnungsart.PA && this.filter) {
        return this.filterTerminArt(Zuordnungsart.PA);
      }

      if (this.zuordnungsart === Zuordnungsart.HKP && this.filter) {
        return this.filterTerminArt(Zuordnungsart.HKP);
      }

      return [];
    },
    autoConfiguredIds: function autoConfiguredIds() {
      if (this.zuordnungsart === Zuordnungsart.PA) {
        return editSettingsStore.getters.terminartenAutoConfiguredPaIds;
      }

      if (this.zuordnungsart === Zuordnungsart.HKP) {
        return editSettingsStore.getters.terminartenAutoConfiguredHkpIds;
      }

      return [];
    },
    autoConfiguredCount: function autoConfiguredCount() {
      if (this.zuordnungsart === Zuordnungsart.PA) {
        return editSettingsStore.getters.terminartenAutoConfiguredPaCount;
      }

      if (this.zuordnungsart === Zuordnungsart.HKP) {
        return editSettingsStore.getters.terminartenAutoConfiguredHkpCount;
      }

      return 0;
    },
    autoConfiguredText: function autoConfiguredText() {
      if (this.zuordnungsart === Zuordnungsart.PA) {
        return "".concat(editSettingsStore.getters.terminartenAutoConfiguredPaCount, " PA-Terminart automatisch anlegen");
      }

      if (this.zuordnungsart === Zuordnungsart.HKP) {
        return "".concat(editSettingsStore.getters.terminartenAutoConfiguredHkpCount, " HKP-Terminarten automatisch anlegen");
      }

      return '';
    }
  },
  beforeMount: function beforeMount() {
    if (this.zuordnungsart === 'pa') {
      this.columnDefs = settingsPaProphyZuordnungColumnsDefs();
    }

    if (this.zuordnungsart === 'hkp') {
      this.columnDefs = settingsHkpZuordnungColumnsDefs();
    }

    this.gridOptions = {
      rowHeight: 40,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      rowSelection: 'multiple',
      suppressRowHoverHighlight: true,
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
    this.rowClassRules = {
      'row-not-configured-even': function rowNotConfiguredEven(params) {
        var _a, _b;

        return params.node.rowIndex && params.node.rowIndex % 2 === 0 && (((_a = params.data) === null || _a === void 0 ? void 0 : _a.paTyp) === PAType.OHNE || ((_b = params.data) === null || _b === void 0 ? void 0 : _b.hkpTyp) === HKPTerminType.OHNE) || false;
      },
      'row-not-configured-odd': function rowNotConfiguredOdd(params) {
        var _a, _b;

        return params.node.rowIndex && params.node.rowIndex % 2 !== 0 && (((_a = params.data) === null || _a === void 0 ? void 0 : _a.paTyp) === PAType.OHNE || ((_b = params.data) === null || _b === void 0 ? void 0 : _b.hkpTyp) === HKPTerminType.OHNE) || false;
      }
    };
  },
  methods: {
    onGridReady: function onGridReady(event) {
      this.gridApi = event.api;
      this.gridColumnApi = event.columnApi;
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    },
    removePendingZuordnung: function removePendingZuordnung(item) {
      var index = this.pendingZuordnungen.indexOf(item.extid);

      if (index >= 0) {
        this.pendingZuordnungen.splice(index, 1);
      }
    },
    commitPendingZuordnungen: function commitPendingZuordnungen() {
      if (this.zuordnungsart === Zuordnungsart.PA) {
        console.log('commitPendingZuordnungen', this.pendingZuordnungen);
        editSettingsStore.commit.addTerminartenPa(this.pendingZuordnungen);
      }

      if (this.zuordnungsart === Zuordnungsart.HKP) {
        editSettingsStore.commit.addTerminartenHkp(this.pendingZuordnungen);
      }

      this.pendingZuordnungen = [];
    },
    commitAutoZuordnung: function commitAutoZuordnung() {
      if (this.zuordnungsart === Zuordnungsart.PA) {
        editSettingsStore.commit.addTerminartenPa(_toConsumableArray(this.autoConfiguredIds));
      }

      if (this.zuordnungsart === Zuordnungsart.HKP) {
        editSettingsStore.commit.addTerminartenHkp(_toConsumableArray(this.autoConfiguredIds));
      }
    },
    terminArtFilter: function terminArtFilter(item, queryText) {
      var rgx = new RegExp(queryText, 'i');

      if (item.bezeichnung.match(rgx)) {
        return true;
      }

      return !!item.extid.match(rgx);
    },
    filterTerminArt: function filterTerminArt(type) {
      var rgx = new RegExp(this.filter, 'i');
      var terminarten = type === Zuordnungsart.PA ? editSettingsStore.state.editSettings.terminArtZuPaType : editSettingsStore.state.editSettings.terminArtZuHkpType;
      return chain(staticDataMetricsStore.state.terminarten).filter(function (ta) {
        return ta.extid in terminarten && (rgx.test(ta.bezeichnung) || rgx.test(ta.extid));
      }).keyBy('extid').keys().value();
    },
    getRowId: function getRowId(params) {
      return params.data;
    }
  }
});