import { defineComponent } from 'vue';
import TerminArtZuordnungTable from '@/components/tables/settings/TerminArtZuordnung/TerminArtZuordnungTable.vue';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  components: {
    TerminArtZuordnungTable: TerminArtZuordnungTable
  },
  data: function data() {
    return {
      pendingZuordnungen: []
    };
  },
  computed: {
    routeName: function routeName() {
      return this.$route.path;
    },
    terminartenNotConfigured: function terminartenNotConfigured() {
      return staticDataMetricsStore.state.terminarten;
    },
    pendingRegresse: {
      get: function get() {
        return editSettingsStore.getters.terminartenConfiguredRegresse;
      },
      set: function set(value) {
        console.log('set', value);
        editSettingsStore.commit.updateTerminartenRegresse(value);
      }
    }
  },
  methods: {
    removePendingRegress: function removePendingRegress(item) {
      editSettingsStore.commit.deleteTerminartRegresse(item.extid);
    }
  }
});