import { IToggleButtonsCell, IDeleteButtonCell } from '@/components/cells/cellInterfaces';
import { IRoseAGGridColumn } from '@rose/common-ui';
import { PAType, ITerminart } from '@rose/types';
import { editSettingsStore } from '@/state/settings/editSettingsStore';

export function settingsPaProphyZuordnungColumnsDefs(): IRoseAGGridColumn<string>[] {
  return [
    {
      headerName: 'Terminart',
      valueGetter: params => {
        if (params.data) {
          const terminart = editSettingsStore.getters.terminartenMap?.[params.data];
          return `${terminart?.bezeichnung} (${terminart?.extid})`;
        }
      },
      sort: 'asc',
      sortable: true,
      width: 350,
      resizable: true,
    },
    {
      headerName: 'Termingruppe',
      valueGetter: params => params.data && editSettingsStore.getters.terminartenMap?.[params.data]?.gruppe,
      field: 'gruppe',
      cellRenderer: 'TermingruppeCell',
      sortable: true,
      width: 200,
      resizable: true,
    },
    {
      headerName: 'PAR-Art',
      flex: 1,
      field: 'paTyp',
      valueGetter: params =>
        params.data && { key: params.data, value: editSettingsStore.getters.terminartenConfiguredPa?.[params.data] },
      cellRenderer: 'ToggleButtonsCell',
      cellRendererParams: {
        context: {
          buttons: [
            {
              value: PAType.PZR,
              name: 'Prophylaxe',
              help: 'Termine in denen Prophylaxeleistungen im Sinne von Zahnreinigung und Biofilm-Management erbracht werden (keine Versiegelung etc.).',
              outlined: true,
            },
            {
              value: PAType.PAV,
              name: 'PAV',
              help: 'Termine, die im Rahmen des PAR-Konzepts der Parodontitis Vorbehandlung dienen.',
              outlined: true,
            },
            {
              value: PAType.PA,
              name: 'PAR',
              help: 'Termine in denen die eigentliche antiinfektiöse Therapie (Scaling- & Root-Planing) stattfindet.',
              outlined: true,
            },
            {
              value: PAType.PAN,
              name: 'PAN',
              help: 'Termine, in denen die Befundevaluation / PA-Nachsorge stattfindet. Bei Kombiterminen BEV/UPT bitte als UPT markieren.',
              outlined: true,
            },
            {
              value: PAType.UPT,
              name: 'BEV/UPT',
              help: 'Termine nach abgeschlossener PA-Behandlung zur unterstützenden Parodontitis Therapie. Bei Kombiterminen BEV/UPT bitte als UPT markieren.',
              outlined: true,
            },
            { value: PAType.OHNE, name: 'Nicht vergeben', color: 'error', outlined: true },
          ],
          clicked(field: { key: ITerminart['extid']; value: PAType }) {
            editSettingsStore.commit.updateTerminArtPaArt({ terminart: field.key, paType: field.value });
          },
        },
      } as IToggleButtonsCell<ITerminart['extid'], PAType>,
      sortable: false,
      width: 400,
    },
    {
      headerName: '',
      cellRenderer: 'DeleteButtonCell',
      width: 40,
      cellStyle: { justifyContent: 'center' },
      cellRendererParams: {
        context: {
          clicked(field: { column: string; row: string }) {
            editSettingsStore.commit.deleteTerminartenPa([field.row]);
          },
        },
      } as IDeleteButtonCell<any, string>,
    },
  ];
}
