export enum Zuordnungsart {
  PA = 'pa',
  HKP = 'hkp',
}

export enum IPraxisTyp {
  none = 'none',
  single = 'single',
  multi = 'multi',
}
